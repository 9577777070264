/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .group-image-horizontal-divider {
        border: rgba(0, 0, 0, 0.08) solid 0.3px;
        text-align: center;
        margin: auto;
    }
    .group-image-container {
        border-right-width: 1px;
        border-right-color: rgba(0, 0, 0, 0.08);
        border-right-style: solid;
    }

    .service-card-image {
        max-width: 230px;
        max-height: 100%;
        display: inline-block;
        float: right;
        /* margin-top: -90px;
		margin-left: 20px;
		margin-right: -50px;
		margin-bottom: -90px; */
        margin: -40px -40px -52px 30px;
    }
    .service-card-image img {
        float: left;
        height: auto;
        border-bottom-left-radius: 100%;
        border-top-left-radius: 100%;
    }
    .service-card-title {
        text-align: left;
    }

    .card-team {
        padding: 0px 30px 40px 30px;
    }

    .subsection-header {
        margin-left: initial;
    }
}
