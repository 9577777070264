@media (width >= 768px) {
  .group-image-horizontal-divider {
    text-align: center;
    border: .3px solid #00000014;
    margin: auto;
  }

  .group-image-container {
    border-right: 1px solid #00000014;
  }

  .service-card-image {
    max-width: 230px;
    max-height: 100%;
    float: right;
    margin: -40px -40px -52px 30px;
    display: inline-block;
  }

  .service-card-image img {
    float: left;
    height: auto;
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
  }

  .service-card-title {
    text-align: left;
  }

  .card-team {
    padding: 0 30px 40px;
  }

  .subsection-header {
    margin-left: initial;
  }
}

/*# sourceMappingURL=index.c1e788cb.css.map */
